<template>
  <div class="fullScreen has-text-left is-flex is-flex-direction-column is-align-items-center is-justify-content-flex-start">
    <p class="title is-5">{{ device.name }} Guides</p>
    <div class="boxGrid maxWidth">
      <p v-if="!device.guides || device.guides.length == 0">
        No guides for this device yet.
      </p>
      <div @click="selectGuide(guide.guideID)" v-for="guide in device.guides" :key="guide.guideID" class="customBox is-clickable is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
        <img class="deviceImage mb-3" src="@/assets/iPhone-11.png">
        <p>
          <strong>{{ guide.name }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Guides",
  title: 'Guides',
  computed: {
    ...mapGetters({
      device: "getSelectedDevice",
    })
  },
  methods: {
    selectGuide(guide) {
      this.$store.dispatch('getGuide', guide);
    }
  }
};
</script>